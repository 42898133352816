<template>
  <button class="btn btn-lg btn-primary btn-block">{{ text }}</button>
</template>

<script>
export default {
  props: {
    text: {
      required: true,
      type: String
    }
  }
}
</script>
