import {alertController, loadingController, toastController} from "@ionic/vue";
import store from "@/store";
import {useRouter} from "vue-router";

export default function authFunctions(){

    const router = useRouter()

    const doLogin = async (user_email, user_pwd) => {

        let toast = await toastController.create({duration: 3000, color: 'danger', position: 'top'})
        if(!user_email.length || !user_pwd.length){
            toast.message = 'Please provide '+(!user_email.length ? 'email address' : 'password')
            await toast.present()
            return false
        }

        let loader = await loadingController.create({message: 'Signing in...'})
        await loader.present()

        let data = {
            grant_type: 'password',
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
            username: user_email,
            password: user_pwd,
        }
        store.dispatch("auth/auth_request", data, {root: true})
            .then( (resp) => {
                router.push( resp.data.role && resp.data.role === 'is-admin' ? '/admin/dashboard' : '/')
            })
            .catch( (err) => {
                console.log(err)
                let message = (err.response.status === 400) ? 'Invalid credentials' : err.response.statusText
                alertController.create({message: message, buttons: ['OK']}).then((alert) => alert.present())
            })
            .then(() => loader.dismiss() )
    }

    return{
        doLogin,
    }
}